import GeneralService from './GeneralService'
import {Component} from 'react'


export class UserService extends Component {
  public static enrollFace(body: string, accessToken: any, loadingHandler: any) {
    return GeneralService.post(`/user/enroll_face`, body, accessToken, loadingHandler, JSON, GeneralService.USER_ENDPOINT)
  }
  public static enrollPhone(body: string,  loadingHandler: any) {
    return GeneralService.post(`/user/enroll_phone`, body, null, loadingHandler, JSON, GeneralService.USER_ENDPOINT)
  }
  public static loginFace(body: string, loadingHandler: any) {
    return GeneralService.post(`/user/login_face`, body, null, loadingHandler, JSON, GeneralService.USER_ENDPOINT)
  }
  public static enrollVoice(body: FormData, accessToken: any, loadingHandler: any) {
    return GeneralService.post(`/user/enroll_voice`, body, accessToken, loadingHandler, JSON, GeneralService.USER_ENDPOINT, true)
  }
  public static verifyVoice(body: FormData, accessToken: any, loadingHandler: any) {
    return GeneralService.post(`/user/verify_voice`, body, accessToken, loadingHandler, JSON, GeneralService.USER_ENDPOINT, true)
  }
  public static uploadVideo(endpoint: string, body: FormData, accessToken: any, loadingHandler: any) {
    return GeneralService.post(`/user/upload_video/${endpoint}`, body, accessToken, loadingHandler, JSON, GeneralService.USER_ENDPOINT, true)
  }
  public static getPassphrase(loadingHandler: any) {
    return GeneralService.get(`/user/get_passphrase`, null, loadingHandler, JSON, GeneralService.USER_ENDPOINT)
  }
  public static updateUser(body: string, accessToken: any, loadingHandler: any) {
    return GeneralService.post(`/user/update`, body, accessToken, loadingHandler, JSON, GeneralService.USER_ENDPOINT)
  }
  public static updatePhone(body: string, accessToken: any, loadingHandler: any) {
    return GeneralService.post(`/user/update/phone`, body, accessToken, loadingHandler, JSON, GeneralService.USER_ENDPOINT)
  }
  public static sendVerificationCode(accessToken: any, loadingHandler: any) {
    return GeneralService.get(`/user/send_phone_verification_code`, accessToken, loadingHandler, JSON, GeneralService.USER_ENDPOINT)
  }
  public static verifyPhone(body: any, accessToken: any, loadingHandler: any) {
    return GeneralService.put(`/user/verify_phone`, body, accessToken, loadingHandler, JSON, GeneralService.USER_ENDPOINT)
  }
  public static refreshUser(body: string, accessToken: any, loadingHandler: any) {
    return GeneralService.post(`/user/refresh/user`, body, accessToken, loadingHandler, JSON, GeneralService.USER_ENDPOINT)
  }
  public static getReferralLink(accessToken: any, loadingHandler: any) {
    return GeneralService.get(`/user/referral_link`, accessToken, loadingHandler, JSON, GeneralService.USER_ENDPOINT)
  }
  public static getCredits(accessToken: any, loadingHandler: any) {
    return GeneralService.get(`/user/credits`, accessToken, loadingHandler, JSON, GeneralService.USER_ENDPOINT)
  }
}