import React, {type FC} from 'react'
import {Button, Typography} from 'antd'
import {User} from '../models/User'

interface AddVehicleTermsProps {
    user: User
    onComplete: any
}

const AddVehicleTermsComponent: FC<AddVehicleTermsProps> = (props) => {


    const acknowledgeTerms = () => {
        props.onComplete();
    }
    return (
        <div style={{ width: '100%' }}>
            <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography style={{ fontWeight: '600', margin: 0 }}>Acknowledge Terms</Typography>
                <Typography.Paragraph style={{ marginTop: '20px' }}>Please click the button below to confirm that you understand and agree with the following statements:
                    <ul>
                        <li>You are the primary driver for this vehicle</li>
                        <li>Your name appears on the vehicle registration</li>
                        <li>Persons with access to this vehicle may use it to pay for goods and services with the payment methods on this account</li>
                        <li>The license plate on this vehicle BELONGS to this vehicle</li>
                    </ul>
                </Typography.Paragraph>
            </div>
            <Button block type={'primary'} onClick={acknowledgeTerms}>I Understand & Agree</Button>
        </div>
    )
}

export default AddVehicleTermsComponent
