import React from 'react';
import {Button, Col, Divider, Progress, Row, Typography} from 'antd';
import {LoyaltyBalance, LoyaltyRedemption, LoyaltyTier} from '../models/Loyalty';
import {formatCurrency} from '../utils/NumberUtils';
import {formatDate} from '../utils/DateUtils';

interface LoyaltyRedemptionTiersComponentProps {
    activateLoyalty: (loyaltyID: number) => void
    loyaltyBalance: LoyaltyBalance | undefined
    hideLocation?: boolean
}
const formatPercent = (points_amount: number, points_threshold: number) => {
    return 100 * points_amount /points_threshold 
}
const LoyaltyRedemptionTiersComponent: React.FC<LoyaltyRedemptionTiersComponentProps> = (props) => {
    if (props.loyaltyBalance == undefined) {
        return <div></div>
    }
    const redeemed = props.loyaltyBalance.available_rewards.map((redemption: LoyaltyRedemption, index: number) => (
        <div key={index}>
            <Row>
                <Col span={18}>
                    <Typography>{formatCurrency(redemption.monetary_value)} off</Typography>
                    <Typography>Claimed {formatDate(redemption.date_created)}</Typography>

                </Col>
                <Col span={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                        <Button disabled>Claimed</Button>
                </Col>
            </Row>
            {(index < (props.loyaltyBalance?.available_rewards.length || 0) - 1 || (props.loyaltyBalance?.redemption_tiers.length || 0) > 0) && <Divider />}
        </div>
    ))
    const redemption_tiers = props.loyaltyBalance.redemption_tiers.map((tier: LoyaltyTier, index: number) => (
        <div key={index}>
            <Row>
                <Col span={18}>
                    <Typography><span style={{fontWeight: '600'}}>{formatCurrency(tier.monetary_value)}</span> off</Typography>

                </Col>
                <Col span={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                    {
                        (props.loyaltyBalance?.points_amount || 0) >= tier.points_threshold &&
                        <Button onClick={() => { props.activateLoyalty(tier.id) }}>Claim</Button>
                    }
                </Col>
            </Row>
            <Row>
                <Col span={21}>
                    <Progress percent={formatPercent(props.loyaltyBalance?.points_amount || 0, tier.points_threshold)} format={() => `${props.loyaltyBalance?.points_amount}/${tier.points_threshold}`} />
                </Col>
            </Row>
            {index < (props.loyaltyBalance?.redemption_tiers.length || 0) - 1 && <Divider />}
        </div>
    ))
    return <>
        {redeemed}
        {redemption_tiers}
    </>
};

export default LoyaltyRedemptionTiersComponent;