import React, {type FC} from 'react'
import {Col, Row, Typography} from 'antd'
import {formatDate} from '../utils/DateUtils'
import {Transaction} from '../models/TransactionTypes'
import {User} from '../models/User'

interface TransactionSummaryProps {
    user: User
    transaction: Transaction
    onClick: any
}

const TransactionSummaryComponent: FC<TransactionSummaryProps> = (props) => {
    return (
        <div onClick={() => props.onClick(props.transaction)}>
            <Row style={{ display: 'flex', alignItems: 'center' }}>
                <Col span={18}>
                    <Row>
                        <Col span={24}>
                            <Typography style={{
                                fontWeight: '600',
                                textAlign: 'left'
                            }}>{props.transaction.location.location_name}</Typography>
                        </Col>
                        <Col span={24}>
                            <Typography style={{ textAlign: 'left' }}>{formatDate(props.transaction.payment.date_created)}</Typography>
                        </Col>
                        <Col span={24} style={{display: 'flex', flexDirection: 'row'}}>
                            <Typography
                                style={{ textAlign: 'left' }}>{props.transaction.mechanism?.mechanism_type == 'FACE' ? 'Face' : props.transaction.mechanism?.nickname}</Typography>
                            <span>{'\u00A0\u2022\u00A0'}</span>
                            <Typography
                                style={{ textAlign: 'left' }}>{props.transaction.payment_method ? props.transaction.payment_method?.nickname : 'N/A'}</Typography>
                        </Col>
                    </Row>
                </Col>
                <Col span={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Typography>${(props.transaction.payment.amount / 100).toFixed(2)}</Typography>
                </Col>
            </Row>
        </div>
    )
}

export default TransactionSummaryComponent
