import {User} from "../models/User";
import React, {FC, useEffect, useState} from "react";
import {Button, Card, Col, Row, Typography} from "antd";
import {WaitlistService} from "../services/WaitlistService";
import {WaitlistEntry} from "../models/WaitlistEntryTypes";
import {CheckCircleOutlined} from "@ant-design/icons";

interface WaitlistComponentProps {
    user: User
    onError: (description: string, title?: string) => void
    waitlistType: string
}

const WaitlistComponent: FC<WaitlistComponentProps> = (props) => {
    const [waitlistEntry, setWaitlistEntry] = useState<any>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [joined, setJoined] = useState<boolean>(false);

    useEffect(() => {
        if (props.user) {
            WaitlistService.getWaitlistEntry(props.waitlistType, props.user.access_token, setLoading).subscribe((data: WaitlistEntry) => {
                setWaitlistEntry(data);
            }, (error: any) => {
                console.error(`Error fetching waitlist status: ${error}`);
                props.onError(error, `Error Fetching Waitlist Status`);
            });
        }
    }, [props.waitlistType, props.user.access_token])


    const joinWaitlist = () => {
        setLoading(true);
        const data = {'waitlist_type': props.waitlistType}
        WaitlistService.addWaitlistEntry(JSON.stringify(data), props.user.access_token, setLoading).subscribe((data: WaitlistEntry) => {
            setWaitlistEntry(data);
            setJoined(true);
        }, (error: any) => {
            console.error(`Error joining waitlist: ${error}`);
            props.onError(error, `Error Joining Waitlist`);
        });
    }

    return (
        <div style={{width: '100%'}}>
            {!waitlistEntry &&
                <Card style={{width: '100%'}} loading={loading}>
                    <Row>
                        <Col span={24} style={{display: 'flex', flexDirection: 'column', alignItems: 'start'}}>
                            <Typography style={{fontWeight: '600', textAlign: 'start'}}>Want VIP promotions & special perks?</Typography>
                        </Col>
                        <Col span={24} style={{display: 'flex', flexDirection: 'column', marginTop: '5px'}}>
                            <Typography.Paragraph style={{textAlign: 'start'}}>
                                Users who pay with their YewPay Wallet earn rewards faster and get access to VIP promotions and
                                special perks. Reserve your spot in line now!
                            </Typography.Paragraph>
                        </Col>
                        <Col span={24}>
                            <Button block type={'primary'} onClick={joinWaitlist}>Join The Waitlist</Button>
                        </Col>
                    </Row>
                </Card>
            }
            {waitlistEntry &&
                <Card style={{width: '100%'}} loading={loading}>
                    <Row style={{width: '100%'}}>
                        <Col span={24} style={{display: 'flex'}}>
                            <div style={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>
                                <CheckCircleOutlined style={{fontSize: '36px', color: '#7ac968'}}/>
                            </div>
                            <div style={{flexGrow: 1, marginLeft: '20px'}}>
                                <Row>
                                    <Col span={24} style={{display: 'flex', flexDirection: 'column', alignItems: 'start'}}>
                                        <Typography style={{fontWeight: '600', textAlign: 'start'}}>You're On The Wallet Waitlist!</Typography>
                                    </Col>
                                    <Col span={24} style={{display: 'flex', flexDirection: 'column'}}>
                                        <Typography.Paragraph style={{textAlign: 'start', marginBottom: 0}}>
                                            Your current position is <span style={{fontWeight: '600'}}>#{waitlistEntry.waitlist_position}</span>
                                        </Typography.Paragraph>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Card>
            }
        </div>
    );

};

export default WaitlistComponent;