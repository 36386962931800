interface dateInfoType {
  month: string
  day: string
  year: string
  hours: string
  minutes: string
  seconds: string
  ampm: string
}
const getDateInfo = (millis: number): dateInfoType => {
  const date = new Date(millis)

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ]

  const getOrdinal = (n: number) => {
    const s = ['th', 'st', 'nd', 'rd']
    const v = n % 100
    return n + (s[(v - 20) % 10] || s[v] || s[0])
  }

  const month = monthNames[date.getMonth()]
  const day = getOrdinal(date.getDate())
  const year = String(date.getFullYear())
  const hours = String(date.getHours() > 12 ? date.getHours() - 12 : date.getHours())
  const minutes = ('0' + date.getMinutes()).slice(-2)
  const seconds = ('0' + date.getSeconds()).slice(-2)
  const ampm = date.getHours() >= 12 ? 'PM' : 'AM'

  return {
    month,
    day,
    year,
    hours,
    minutes,
    seconds,
    ampm
  }
}
export function formatDateTime (millis: number) {
  const { month, day, year, hours, minutes, seconds, ampm } = getDateInfo(millis)
  return `${month} ${day}, ${year} ${hours}:${minutes}:${seconds}${ampm}`
}

export function formatDate (millis: number, showYear: boolean = true) {
  const { month, day, year } = getDateInfo(millis)
  if (!showYear) {
    return `${month} ${day}`
  }
  return `${month} ${day}, ${year}`
}
