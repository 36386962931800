import React, {type FC, useEffect, useRef, useState} from 'react'
import {Button, Col, Row, Typography} from 'antd'
import FindAccountModal from './FindAccountModal'
import RegisterAccountModal from './RegisterAccountModal'
import WebcamStreamCapture from '../WebcamStreamCapture'
import logo from '../assets/yewpay_logo_positive_primary.svg';
import {useLocation, useNavigate} from 'react-router-dom'
import carousel1 from '../assets/landing_carousel_1.svg';
import carousel2 from '../assets/landing_carousel_2.svg';
import carousel3 from '../assets/landing_carousel_3.svg';
import carousel4 from '../assets/landing_carousel_4.svg';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import ContactSupportNonUserModal from "./ContactSupportNonUserModal";
import Slider from 'react-slick';
import './AuthComponent.css';


interface AuthComponentProps {
  onUserLogin: (userData: any) => void
  onError: (description: string, title?: string) => void
}
const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
const AuthComponent: FC<AuthComponentProps> = (props) => {
  const query = useQuery();
  const [findAccountModalVisible, setFindAccountModalVisible] = useState<boolean>(false)
  const [registerAccountModalVisible, setRegisterAccountModalVisible] = useState<boolean>(false)
  // const videoRecorder = useRef<WebcamStreamCapture>(new WebcamStreamCapture())
  const [fingerprint, setFingerprint] = React.useState('');
  const [contactSupportModalOpen, setContactSupportModalOpen] = useState(false);
  const contentStyle: React.CSSProperties = {
    margin: 0,
    height: '160px',
    color: '#fff',
    lineHeight: '160px',
    textAlign: 'center',
    background: '#364d79',
  };


  useEffect(() => {
    const setFp = async () => {
      const fp = await FingerprintJS.load();

      const { visitorId } = await fp.get();
      setFingerprint(visitorId);
    };

    setFp();
  }, []);

  const onCarouselChange = (currentSlide: number) => {
    console.log(currentSlide);
  };
  
  const showFindAccountModal = (): void => {
    // videoRecorder.current.reset()
    // videoRecorder.current.startRecording()
    setFindAccountModalVisible(true)
  }

  const showRegisterAccountModal = (): void => {
    // videoRecorder.current.reset()
    // videoRecorder.current.startRecording()
    setRegisterAccountModalVisible(true)
  }
  const navigate = useNavigate()
  const goToNewUser = (): void => {
      navigate(`/no_card`)
  }

  const handleLogin = (userData: any): void => {

    props.onUserLogin(userData.user)
    if (userData.user && !userData.user.payment_methods_available) {
      goToNewUser()
    }

    // videoRecorder.current.stopRecording(() => {
    //   videoRecorder.current.uploadVideo(userData.user.access_token, 'verify')
    //     .then(() => console.log(''))
    //     .catch((error: Error) => {
    //       console.error('Failed to upload video:', error)
    //       window.heap.track('video_upload_error', { 'message': error });
    //     });
    // })
  }

  const handleCancel = (): void => {
    // videoRecorder.current.stopRecording(() => {});
  }

  const openSupportModal = (): void =>  {
    handleCancel();
    setFindAccountModalVisible(false);
    setContactSupportModalOpen(true);
  }

  return (
    <div style={{height: '100%', maxHeight: '100%', width: '100%', display: 'flex', flexDirection: 'column'}}>
      <Row style={{flex: '0 0 15%', maxHeight: '15%', overflow: 'auto', alignItems: 'center', justifyContent: 'center', width: '100%'}}>
        <img src={logo} alt="Logo" style={{height: '40px', zIndex: 2}}/>
      </Row>
      <Row style={{flex: '0 0 65%', maxHeight: '65%', width: '100%', overflow: 'hidden', paddingBottom: '20px'}}>
        <div style={{width: '100%', height: '100%'}}>
          <Slider dots={true} className={'carousel'}>
            <div>
              <Row style={{flex: '0 0 60%', maxHeight: '60%', width: '100%%', overflow: 'hidden'}}>
                <Col span={24} style={{display: 'flex', justifyContent: 'center'}}>
                  <img src={carousel1} alt="carousel-1" style={{width: '75%'}}/>
                </Col>
              </Row>
              <Row style={{flex: '0 0 10%', maxHeight: '20%', width: '100%', overflow: 'hidden', marginTop: '10px'}}>
                <Typography style={{fontSize: '18px', fontWeight: 'bold'}}>Welcome to YewPay!</Typography>
              </Row>
              <Row style={{flex: '0 0 30%', maxHeight: '30%', width: '100%', overflow: 'hidden'}}>
                <Typography style={{fontSize: '14px', textAlign: 'start'}}>
                  Streamline payments and automate loyalty rewards by enabling your favorite merchants to anonymously recognize you. Try today and we'll give you <span style={{fontWeight: '600'}}>$5</span> to put toward your first order.
                </Typography>
              </Row>
            </div>
            <div>
              <Row style={{flex: '0 0 60%', maxHeight: '60%', width: '100%%', overflow: 'hidden'}}>
                <Col span={24} style={{display: 'flex', justifyContent: 'center'}}>
                  <img src={carousel2} alt="carousel-2" style={{width: '75%'}}/>
                </Col>
              </Row>
              <Row style={{flex: '0 0 10%', maxHeight: '10%', width: '100%', overflow: 'hidden', marginTop: '10px'}}>
                <Typography style={{fontSize: '18px', fontWeight: 'bold'}}>Private & Secure</Typography>
              </Row>
              <Row style={{flex: '0 0 30%', maxHeight: '30%', width: '100%', overflow: 'hidden'}}>
                <Typography style={{fontSize: '14px', textAlign: 'start'}}>
                  Never share your payment info or phone number for loyalty ever again! YewPay allows merchants to tender payment and handle loyalty while you remain anonymous.
                </Typography>
              </Row>
            </div>
            <div>
              <Row style={{flex: '0 0 60%', maxHeight: '60%', width: '100%%', overflow: 'hidden'}}>
                <Col span={24} style={{display: 'flex', justifyContent: 'center'}}>
                  <img src={carousel3} alt="carousel-3" style={{width: '75%'}}/>
                </Col>
              </Row>
              <Row style={{flex: '0 0 10%', maxHeight: '10%', width: '100%', overflow: 'hidden', marginTop: '10px'}}>
                <Typography style={{fontSize: '18px', fontWeight: 'bold'}}>Earn Rewards</Typography>
              </Row>
              <Row style={{flex: '0 0 30%', maxHeight: '30%', width: '100%', overflow: 'hidden'}}>
                <Typography style={{fontSize: '14px', textAlign: 'start'}}>
                  Merchant loyalty points are added automatically after you make a purchase. Track your points and rewards against all merchants in a single app effortlessly!
                </Typography>
              </Row>
            </div>
            <div>
              <Row style={{flex: '0 0 60%', maxHeight: '60%', width: '100%%', overflow: 'hidden'}}>
                <Col span={24} style={{display: 'flex', justifyContent: 'center'}}>
                  <img src={carousel4} alt="carousel-4" style={{width: '75%'}}/>
                </Col>
              </Row>
              <Row style={{flex: '0 0 10%', maxHeight: '10%', width: '100%', overflow: 'hidden', marginTop: '10px'}}>
                <Typography style={{fontSize: '18px', fontWeight: 'bold'}}>Ditch Your Wallet</Typography>
              </Row>
              <Row style={{flex: '0 0 30%', maxHeight: '30%', width: '100%', overflow: 'hidden'}}>
                <Typography style={{fontSize: '14px', textAlign: 'start'}}>
                  Set rules for when to charge your different payment methods, or choose which card to charge afterwards.
                </Typography>
              </Row>
            </div>
          </Slider>
        </div>
      </Row>
      <Row style={{flex: '0 0 20%', maxHeight: '20%', overflow: 'auto', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
        <Button type="primary" onClick={showRegisterAccountModal} block>
          Sign Up
        </Button>
        <Button onClick={showFindAccountModal} block style={{marginTop: '10px'}}>
          Login
        </Button>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: '20px' }} onClick={openSupportModal}><a>Need Help?</a></div>
      </Row>
        <FindAccountModal
          visible={findAccountModalVisible}
          setVisible={setFindAccountModalVisible}
          goToRegister={showRegisterAccountModal}
          onLogin={handleLogin}
          onError={props.onError}
          onCancel={handleCancel}
          deeplink={query.get('deeplink') || undefined}
          fingerprint={fingerprint}
          openSupportModal={openSupportModal}
          />
        <RegisterAccountModal
          visible={registerAccountModalVisible}
          setVisible={setRegisterAccountModalVisible}
          onRegister={handleLogin}
          onError={props.onError}
          deeplink={query.get('deeplink') || undefined}
          fingerprint={fingerprint}
        />
      <ContactSupportNonUserModal visible={contactSupportModalOpen} setVisible={setContactSupportModalOpen} onError={props.onError} />
    </div>
  )
}

export default AuthComponent
