import React, { type FC } from 'react'
import { Button, Col, Row, Typography } from 'antd'
import { User } from "../models/User";
import { Mechanism, VehicleMeta } from "../models/MechanismTypes";
import { formatDate } from "../utils/DateUtils";
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

interface VehicleSummaryProps {
  user: User
  mechanism: Mechanism
  onClick: any
  onAccept: any
  onDecline: any
}

const VehicleSummary: FC<VehicleSummaryProps> = (props) => {
  return (
    <div >
      <Row style={{ display: 'flex', alignItems: 'center' }} onClick={() => props.onClick(props.mechanism)}>
        <Typography style={{
          fontWeight: '600',
          textAlign: 'left'
        }}>
          {props.mechanism?.nickname} (...{(props.mechanism?.meta as VehicleMeta)?.plate?.slice(-3)})
        </Typography>
      </Row>
      <Row >
        <Col xs={14} onClick={() => props.onClick(props.mechanism)}>
          <Typography style={{ textAlign: 'left' }}>Added {formatDate(+props.mechanism?.date_created)}</Typography>
        </Col>
        <Col xs={10} style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
          {
            props.mechanism.status == 'PENDING' &&
            <div>
              <Button onClick={() => props.onAccept(props.mechanism)} shape="circle" icon={<CheckOutlined />} style={{ color: 'green', borderColor: 'green' }} />
              <Button onClick={() => props.onDecline(props.mechanism)} shape="circle" icon={< CloseOutlined />} style={{ color: 'red', borderColor: 'red', marginLeft: 5 }} />
            </div>
          }
          {
            props.mechanism.status != 'PENDING' &&
          <Typography style={{ textAlign: 'right', color: props.mechanism.status == 'ACTIVE' ? 'green' : 'red' }}>{props.mechanism.status}</Typography>
          }
        </Col>
      </Row>

    </div>
  )
}

export default VehicleSummary
