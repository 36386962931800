import React, {type FC, useEffect, useState} from 'react'
import {Card, Col, Row, Space, Typography} from 'antd'
import {User} from '../models/User'
import {LoyaltyActivity, LoyaltyBalance} from '../models/Loyalty'
import {useNavigate, useParams} from 'react-router-dom'
import LoyaltyRedemptionTiersComponent from './LoyaltyRedemptionTiersComponent'
import {LoyaltyService} from '../services/LoyaltyService'
import LoyaltyActivityListComponent from './LoyaltyActivityListComponent'

interface LoyaltyDetailScreenProps {
  user: User
  onError: (description: string, title?: string) => void
}

const LoyaltyDetailScreen: FC<LoyaltyDetailScreenProps> = (props) => {
  const [loyaltyActivity, setLoyaltyActivity] = useState<LoyaltyActivity[]>([])
  const [loyaltyBalance, setLoyaltyBalance] = useState<LoyaltyBalance>()
  const [redeemCount, setRedeemCount] = useState<number>(0)
  const [loyaltyBalanceLoading, setLoyaltyBalanceLoading] = useState<boolean>(false)
  const [loyaltyActivityLoading, setLoyaltyActivityLoading] = useState<boolean>(false)
  const { id } = useParams()
  useEffect(() => {
    LoyaltyService.getLoyaltyBalanceByLocation(id, props.user.access_token, redeemCount == 0 ? setLoyaltyBalanceLoading : null).subscribe(
      (data: LoyaltyBalance) => {
        setLoyaltyBalance(data)

      }, (error: any) => {
        console.error(error)
        props.onError(error)
      }
    )
    LoyaltyService.getLoyaltyActivityByLocation(id, 0, 5, props.user.access_token, redeemCount == 0 ? setLoyaltyActivityLoading : null).subscribe(
      (data: LoyaltyActivity[]) => {
        setLoyaltyActivity(data)

      }, (error: any) => {
        console.error(error)
        props.onError(error)
      }
    )
  }, [props.user, redeemCount])
  const activateLoyalty = (redemptionID: number) => {
    LoyaltyService.activateLoyaltyReward(redemptionID, props.user.access_token, null).subscribe((data: any) => {
      setRedeemCount(redeemCount + 1)
    }, (error: any) => {
      console.error(error);
      props.onError(error, 'Error Activating Reward')

    })
  }
  const navigate = useNavigate()
  const goToAllLoyaltyActivity = () => {
    navigate(`/rewards/loyalty/${loyaltyBalance?.location.id}/activity`)
  }
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Row>
        <Col span={24} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          <Typography style={{ color: 'black', fontWeight: '600', fontSize: '18px' }}>{loyaltyBalance?.location.location_name}</Typography>
        </Col>
      </Row>
      <Space />
      <Row style={{overflow: 'auto', marginTop: '20px' }}>
        <Col span={24} style={{ width: '100%'}}>
          <Card style={{ height: 'auto', overflowY: 'auto', width: '100%', textAlign: 'left' }} bodyStyle={{ paddingTop: 0 }} headStyle={{ marginBottom: 12 }} title={'Rewards'} loading={loyaltyBalanceLoading}>
            <Row>
              <Col span={24}>
                <LoyaltyRedemptionTiersComponent activateLoyalty={activateLoyalty} loyaltyBalance={loyaltyBalance} />
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={24} style={{ width: '100%', marginTop: 20 }}>
          <Card style={{ height: 'auto', overflowY: 'auto', textAlign: 'left' }} title={'Activity'} loading={loyaltyActivityLoading} extra={<a onClick={goToAllLoyaltyActivity}>{loyaltyActivity.length > 4 ? 'View All' : ''}</a>}>
            <LoyaltyActivityListComponent loyaltyActivity={loyaltyActivity.slice(0, 4)} />
          </Card>
        </Col>
      </Row>
    </div>
  )
}
export default LoyaltyDetailScreen
