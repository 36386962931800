import GeneralService from './GeneralService'
import {Component} from 'react'

export class SupportService extends Component {
  public static insertSupport(data: any, accessToken: any, loadingHandler: any) {
    return GeneralService.post(`/support/add`, data, accessToken, loadingHandler, JSON)
  }
  public static insertSupportNonUser(data: any, accessToken: any, loadingHandler: any) {
    return GeneralService.post(`/support/add_non_user`, data, accessToken, loadingHandler, JSON)
  }
}