import {FC, useEffect, useMemo, useState} from "react";
import {InfoWindow, Map, Marker, useMap} from '@vis.gl/react-google-maps';
import styles from './HomeComponent.module.css';
import {MapCameraChangedEvent} from "@vis.gl/react-google-maps/dist/components/map/use-map-events";
import {LocationService} from "../services/LocationService";
import {User} from "../models/User";
import {Location} from "../models/LocationTypes";
import {formatCoordinates} from "../utils/NumberUtils";
import './MapComponent.css';
import {FloatButton, Row, Typography} from "antd";
import {AimOutlined, InfoCircleOutlined, StarOutlined} from "@ant-design/icons";

interface MapComponentProps {
    user: User
    onError: (description: string, title?: string) => void
}

const MapComponent: FC<MapComponentProps> = (props) => {

    // State for user's current location
    const [location, setLocation] = useState({
        lat: 37.491441, // default latitude
        lng: -122.2400705 // default longitude
    });
    const [showOverlay, setShowOverlay] = useState(false); // State to control overlay visibility
    const [loading, setLoading] = useState(false);
    const [bounds, setBounds] = useState<any>(null);
    const [locations, setLocations] = useState<any>([]);
    const [selectedLocation, setSelectedLocation] = useState<any>(null);
    const map = useMap();
    const mapStyles = [
        {
            "featureType": "poi",
            "stylers": [
                { "visibility": "off" }
            ]
        }
    ]


    useEffect(() => {
        // Check if the geolocation API is available in the browser
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    // Update state with the new location
                    setLocation({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    });
                },
                error => {
                    console.error("Error getting the geolocation: ", error);
                    setShowOverlay(true); // Show overlay if there's an error or no permission
                }
            );
        } else {
            console.log("Geolocation is not supported by this browser.");
            setShowOverlay(true);
        }
    }, []);

    useEffect(() => {
        if (bounds != null) {
            refreshLocations();
        }
    }, [bounds])

    useEffect(() => {

    }, [selectedLocation]);

    useEffect(() => {
        if (selectedLocation) {
            const isSelectedLocationPresent = locations.some((loc: Location) => loc.location_code === selectedLocation.location_code);

            if (!isSelectedLocationPresent) {
                closeInfoWindow();
            }
        }
    }, [locations])

    // useMemo to memoize InfoWindow content based on selectedLocation
    const infoWindowContent = useMemo(() => {
        if (!selectedLocation) return null;

        return (
            <div>
                <Row>
                    <Typography style={{fontWeight: '600', fontSize: 12}}>
                        {selectedLocation.location_name}
                    </Typography>
                </Row>
                <Row>
                    <Typography style={{fontSize: 12}}>
                        {selectedLocation.addr}
                    </Typography>
                </Row>
                {selectedLocation.loyalty_description && selectedLocation.loyalty_description.length > 0 &&
                    <Row style={{marginTop: '8px'}}>
                        <StarOutlined style={{color: '#7ac968'}}/>
                        <Typography
                            style={{marginLeft: '6px', fontWeight: '600', fontSize: 12, color: '#7ac968'}}>
                            {selectedLocation.loyalty_description}
                        </Typography>
                    </Row>
                }
                {selectedLocation.url && selectedLocation.url.length > 0 &&
                    <Row style={{display: 'flex', justifyContent: 'end', marginTop: '8px'}}>
                        <Typography.Link href={selectedLocation.url} target={'_blank'}
                                         style={{fontSize: '12px'}}>
                            More Info <InfoCircleOutlined/>
                        </Typography.Link>
                    </Row>
                }
            </div>
        );
    }, [selectedLocation]); // Depend on selectedLocation and loading state


    function debounce<T extends (...args: any[]) => void>(func: T, wait: number): (...args: Parameters<T>) => void {
        let timeout: NodeJS.Timeout | null = null;

        return function(...args: Parameters<T>): void {
            const later = () => {
                timeout = null;
                func(...args);
            };

            if (timeout) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(later, wait);
        };
    }

    const handleBoundsChange = (event: MapCameraChangedEvent) => {
        const bounds = event.map.getBounds();
        // event.detail {"center":{"lat":37.59144890000001,"lng":-122.06294000000001},"zoom":11,"heading":0,"tilt":0,"bounds":{"south":37.49617319741868,"west":-122.1831029638672,"north":37.686602787496504,"east":-121.94277703613282}}
        setBounds(bounds);
    };

    const debouncedHandleBoundsChange = debounce(handleBoundsChange, 300);

    const refreshLocations = () => {
        const ne = bounds.getNorthEast();
        const sw = bounds.getSouthWest();
        setLoading(true);
        LocationService.getLocations(ne, sw, props.user.access_token, setLoading).subscribe(
            (data: Location[]) => {
                setLocations(data)
            }, (error: any) => {
                console.error(error)
                props.onError(error)
            }
        )
    }

    const onMarkerClick = (event: google.maps.MapMouseEvent, index: number, location: Location) => {
        event.stop();
        setSelectedLocation(location);
    }

    const closeInfoWindow = () => {
        setSelectedLocation(null);
    }

    const recenter = () => {
        map?.setCenter(location);
        map?.setZoom(11);
    }

    return (
        <div className={styles.mapWrapper}>
            {showOverlay && (
                <div className={styles.overlay}>
                    Enable location services to see local merchants
                </div>
            )}
            <FloatButton className={styles.recenterButton} onClick={() => recenter()} icon={<AimOutlined />}></FloatButton>
            <div className={styles.map}>
                <Map
                    zoom={11}
                    center={{lat: location.lat, lng: location.lng}}
                    gestureHandling={'greedy'}
                    disableDefaultUI={true}
                    onBoundsChanged={debouncedHandleBoundsChange}
                    keyboardShortcuts={false}
                    clickableIcons={false}
                    maxZoom={18}
                    minZoom={9}
                    onClick={() => closeInfoWindow()}
                    styles={mapStyles}
                >
                {locations.map((loc: Location, index: number) => {
                    const coords = formatCoordinates(loc.coordinates);
                    return <Marker key={index} position={coords} onClick={(event) => onMarkerClick(event, index, loc)}
                                   icon={{
                                       url: (require(loc.status == 'ACTIVE' ? '../assets/marker_positive.svg' : '../assets/marker_negative.svg').default),
                                       fillColor: '#ffffff',
                                       scaledSize: loc.status == 'ACTIVE' ? new google.maps.Size(20, 33) : new google.maps.Size(15, 25)
                                   }} />;
                })
                }
                {selectedLocation !== null &&
                    <InfoWindow position={formatCoordinates(selectedLocation.coordinates)} onCloseClick={closeInfoWindow} maxWidth={300} minWidth={250}>
                        {infoWindowContent}
                    </InfoWindow>
                }
                </Map>
            </div>
        </div>
    );
};

export default MapComponent;