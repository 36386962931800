import GeneralService from './GeneralService'
import {Component} from 'react'


export class LoyaltyService extends Component {
  public static getLoyaltyBalances(offset: number, limit: number, accessToken: any, loadingHandler: any) {
    return GeneralService.get(`/loyalty/${offset}/${limit}`, accessToken, loadingHandler, JSON, GeneralService.USER_ENDPOINT)
  }
  public static getLoyaltyBalanceByLocation(locationID: any, accessToken: any, loadingHandler: any) {
    return GeneralService.get(`/loyalty/${locationID}`, accessToken, loadingHandler, JSON, GeneralService.USER_ENDPOINT)
  }
  public static getLoyaltyActivityByLocation(locationID: any, offset: number, limit: number, accessToken: any, loadingHandler: any) {
    return GeneralService.get(`/loyalty/activity/${locationID}/${offset}/${limit}`, accessToken, loadingHandler, JSON, GeneralService.USER_ENDPOINT)
  }
  public static activateLoyaltyReward(redemption_id: number, accessToken: any, loadingHandler: any) {
    return GeneralService.put(`/loyalty/redemption/activate/${redemption_id}`, '', accessToken, loadingHandler, JSON, GeneralService.USER_ENDPOINT)
  }
}