import React, {type FC, useCallback, useEffect, useRef, useState} from 'react'
import Webcam from 'react-webcam'
import {Button, Modal, Spin, Typography} from 'antd'
import {UserService} from '../services/UserService'
import {VideoConstraints} from '../videoConstants'
import {User} from '../models/User'
import {PhoneNumberInput, registerPhone} from './PhoneNumberInput'
import PhoneVerification from './PhoneVerification'
import CameraPermissions from "../utils/CameraPermissions";

interface FindAccountModalProps {
  visible: boolean
  setVisible: (visible: boolean) => void
  goToRegister: () => void
  onLogin: (userData: any) => void
  onError: (description: string, title?: string) => void
  onCancel: () => void
  deeplink: string | undefined
  fingerprint: string
  openSupportModal: () => void
}

const FindAccountModal: FC<FindAccountModalProps> = (props) => {
  const webcamRef = useRef<Webcam | null>(null)
  const [mode, setMode] = useState<string>()
  const [user, setUser] = useState<User>()
  const [phone, setPhone] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (user?.phone_verified && user?.phone && user?.device_verified) {
      setLoading(false)
      props.onLogin({ user: user })
      handleCancel()
    }
    if (user && user?.phone && (!user?.phone_verified || !user?.device_verified)) {
      setLoading(false)
      setMode("verify-phone")
    } else if (user && !user?.phone) {
      setLoading(false)
      setMode('enter-phone')
    } else {
      setMode(undefined)
    }
  }, [user])
  useEffect(() => {
    // This will be executed if the component unmounts
    return () => {
      handleCancel();
    };
  }, []);

  const handleCancel = useCallback((): void => {
    // When the modal closes, stop the webcam stream
    const videoElem = webcamRef.current?.video

    if (videoElem?.srcObject != null) {
      const stream = videoElem.srcObject as MediaStream
      const tracks = stream.getTracks()

      tracks.forEach((track) => {
        track.stop()
      })
    }

    props.setVisible(false)

    props.onCancel();
  }, [])
  const handleLogin = useCallback((): void => {
    setLoading(true)
    const imageSrc = webcamRef?.current?.getScreenshot();
    UserService.loginFace(JSON.stringify({
      'image': imageSrc,
      'deeplink': props.deeplink,
      'fingerprint': props.fingerprint
    }), null).subscribe((data: any) => {
      setUser(data.user)
    }, (error: any) => {
      setLoading(false)
      console.error(`Error verifying face: ${error}`);
      try {
        const errorJson = JSON.parse(error)
        props.onError(errorJson.statusText || "Unknown error occurred. Please try again")
      } catch (err) {
        props.onError("Unknown error occurred. Please try again")
      }
    })
  }, [webcamRef, props.fingerprint])

  const handleGoToRegister = useCallback((): void => {
    handleCancel()
    props.goToRegister()
  }, [])
  const updatePhone = useCallback((): void => {
    if (phone && user) {
      registerPhone(phone, user, null, (data: any) => {
        setUser(data)
      }, (error: any) => {
        console.error(error)
        props.onError(error)
      })
      setPhone('')
    }
  }, [phone, user])
  let content = null;
  switch (mode) {
    case "verify-phone":
      content = <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <PhoneVerification user={user} onError={props.onError} setUser={setUser} fingerprint={props.fingerprint} header='Verify Device' />
      </div>
      break;
    case "enter-phone":
      content = <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <PhoneNumberInput user={user} setPhone={setPhone} showHeader={true} />
        <Button type={'primary'} style={{ marginTop: '10px' }} onClick={() => { updatePhone() }}>Register Phone</Button>
      </div>
      break;
    default:
      content = <div>
          <CameraPermissions/>
        <div style={{ padding: '10px', display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          <Typography style={{ fontWeight: 'bold', fontSize: '18px' }}>Let's Find Your Account</Typography>
          <p>Center your face in the camera view and tap the button below.</p>
        </div>
        <div style={{ flexGrow: 1, backgroundColor: '#000', width: '100%', borderRadius: '8px'}}>
          <Webcam
            audio={false}
            key={Date.now()}
            ref={webcamRef}
            style={{ width: '100%', objectFit: 'cover', borderRadius: '8px' }}
            videoConstraints={VideoConstraints}
            onUserMediaError={error => {
              console.error('Webcam Error: ', error)
              props.onError(error.toString(), 'Webcam Error')
            }}
          />
        </div>
        
        <Button block type={'primary'} style={{ marginTop: '10px', height: '60px' }} disabled={loading} onClick={() => { handleLogin() }}>Login {loading && <Spin style={{paddingLeft: 10}} size='small' />}</Button>
      </div>
      break;
  }
  return (
    <Modal
      open={props.visible}
      onCancel={handleCancel}
      footer={null}
      destroyOnClose={true}
      style={{ display: 'flex', flexDirection: 'column', top: 0, marginTop: '10px' }}
      bodyStyle={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
      {content}
    </Modal>
  )
}

export default FindAccountModal
