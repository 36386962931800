import GeneralService from './GeneralService'
import {Component} from 'react'


export class PromoService extends Component {
  public static getPromos(accessToken: any, loadingHandler: any) {
    return GeneralService.get(`/promo/promos`, accessToken, loadingHandler, JSON, GeneralService.USER_ENDPOINT)
  }
  public static claimPromo(promo_id: number, accessToken: any, loadingHandler: any) {
    return GeneralService.put(`/promo/claim/${promo_id}`, '', accessToken, loadingHandler, JSON, GeneralService.USER_ENDPOINT)
  }
}