import React, {CSSProperties, useEffect, useState} from 'react';
import {Button, Card, Input, Space, Typography} from 'antd';
import {CopyOutlined, ShareAltOutlined} from '@ant-design/icons';
import {User} from '../models/User';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {UserService} from '../services/UserService';


interface ReferProps {
    user: User
    onError: (description: string, title?: string) => void
}

const Refer: React.FC<ReferProps> = (props) => {
    const [referralLink, setReferralLink] = useState<string>("")
    const [copied, setCopied] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(true)
    useEffect(() => {
        if (props.user) {
            UserService.getReferralLink(props.user.access_token, setLoading).subscribe((data: any) => {
                setReferralLink(data.url);
            }, (error: any) => {
                console.error(`Error fetching referral link: ${error}`);
                props.onError(error, `Error Fetching Referral Link`);
            });
        }
    }, [props.user.access_token])

    const style: CSSProperties = {
        "color": "black",
        "backgroundColor": "white"
    }
    if (copied) {
        style["color"] = 'white'
        style["backgroundColor"] = '#7ac968'
        style["borderColor"] = '#7ac968'
        style["textAlign"] = 'center'
    }
    const shareData = {
        title: "YewPay",
        text: "Sign up for YewPay and we both get $5 off our next purchase!",
        url: referralLink,
    };

    return <Card style={{ width: '100%' }}  headStyle={{ textAlign: 'start' }} loading={loading}>
        <Typography style={{textAlign: 'left'}}><span style={{fontWeight: '600'}}>Refer a friend</span> and you'll both get <span style={{fontWeight: '600', color: '#7ac968'}}>$5</span> off your next purchase!</Typography>
        <Space.Compact style={{ width: '100%', marginTop: '10px' }}>
            <Input value={copied ? "Copied" : referralLink} style={style} disabled/>
            <CopyToClipboard text={referralLink}
                onCopy={() =>setCopied(true)}>
            <Button type="primary"><CopyOutlined /></Button>
            </CopyToClipboard>
            <Button onClick={() => {
                navigator.share(shareData).catch(console.error)
            }}><ShareAltOutlined/></Button>
        </Space.Compact>
    </Card>
};

export default Refer;