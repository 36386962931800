import React, {type FC, useState} from 'react'
import {Button, Col, Input, Modal, Row, Typography} from 'antd'
import {PhoneNumberInput} from "./PhoneNumberInput";
import {SupportService} from "../services/SupportService";

const { TextArea } = Input;

interface ContactSupportModalProps {
    visible: boolean
    setVisible: (visible: boolean) => void
    onError: (description: string, title?: string) => void
}

const ContactSupportModal: FC<ContactSupportModalProps> = (props) => {
    const [loading, setLoading] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [comments, setComments] = useState('');
    const [phone, setPhone] = useState<string>();


    const onCancel = () => {
        props.setVisible(false);
        setSubmitted(false);
        setComments('');
    }

    const handleCommentsChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComments(e.target.value);
    }

    const submit = () => {
        setLoading(true);
        const data = {'comments': comments, 'phone': phone}
        SupportService.insertSupportNonUser(JSON.stringify(data), null, setLoading).subscribe((data: any) => {
            setSubmitted(true);
        }, (error: any) => {
            console.error(`Error adding support ticket: ${error}`);
            props.onError(error, `Error Submitting Support Ticket`);
            setLoading(false);
        });
    }

    return (
        <Modal
            open={props.visible}
            onCancel={onCancel}
            footer={null}
            style={{ display: 'flex', flexDirection: 'column', top: 0, marginTop: '10px' }}
            bodyStyle={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Row>
                <Col span={24}>
                    <div style={{ width: '100%' }}>
                        <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <h2 style={{ fontWeight: '600', margin: 0 }}>Contact Support</h2>
                        </div>
                    </div>
                </Col>
            </Row>
            {!submitted &&
             <div>
                 <Row>
                     <p>Summarize your question or concern in the area below.</p>
                 </Row>
                 <Row>
                     <TextArea rows={6} placeholder="Write your question or concern here..."
                               value={comments} onChange={handleCommentsChange}/>
                 </Row>
                 <Row>
                     <p>What's a good cell phone number to text you at?</p>
                 </Row>
                 <Row>
                     <PhoneNumberInput setPhone={setPhone} user={undefined} showHeader={false} padding={0}/>
                 </Row>
                 <Row style={{marginTop: '40px'}}>
                     <Col span={24}>
                         <Button block type={'primary'} onClick={submit} loading={loading}>Submit</Button>
                     </Col>
                 </Row>
             </div>
            }
            {submitted &&
                <div>
                    <Row>
                        <Typography>Submitted! We'll get back to you within 24 hours.</Typography>
                    </Row>
                    <Row style={{marginTop: '40px'}}>
                        <Col span={24}>
                            <Button block type={'primary'} onClick={onCancel}>Done</Button>
                        </Col>
                    </Row>
                </div>
            }
        </Modal>

    )
}

export default ContactSupportModal;
