import React, {type FC, useEffect, useState} from 'react'
import {Col, Row, Typography} from 'antd'
import {MoreOutlined} from '@ant-design/icons'
import {useParams} from 'react-router-dom'
import {formatDateTime} from '../utils/DateUtils'
import {formatCurrency} from '../utils/NumberUtils'
import {TransactionsService} from '../services/TransactionsService'
import {User} from '../models/User'
import {Transaction} from '../models/TransactionTypes'
import {VehicleMeta} from '../models/MechanismTypes'

interface TransactionProps {
    user: User
    onError: (description: string, title?: string) => void
}

const TransactionDetailScreen: FC<TransactionProps> = (props) => {
    const { id } = useParams()
    const [transaction, setTransaction] = useState<Transaction>()
    const [loading, setLoading] = useState<boolean>(false)
    useEffect(() => {
        TransactionsService.getTransactionByID(id, props.user.access_token, setLoading).subscribe((data: Transaction) => {
            setTransaction(data)
        }, (error: any) => {
            console.error(`Error fetching transactions: ${error}`);
            props.onError(error, `Error Fetching Transactions`);
})
    }, [props.user])
    let identifier = ""
    switch (transaction?.mechanism.mechanism_type) {
        case 'VEHICLE':
            const plate = (transaction?.mechanism.meta as VehicleMeta)?.plate
            identifier = plate?.substring(plate?.length - 3)
            break;

        default:
            break;
    }
    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <Row>
                <Col span={20} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                    <Typography style={{ color: 'black', fontWeight: '600', fontSize: '18px' }}>Transaction Detail</Typography>
                </Col>
                <Col span={4} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <MoreOutlined style={{ fontSize: '20px' }} />
                </Col>
            </Row>
            <Row style={{ marginTop: '30px' }}>
                <Col span={24}>
                    <Row style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                        <Col span={24}>
                            <Typography style={{ fontWeight: '600', color: 'black' }}>Location</Typography>
                        </Col>
                        <Col span={24}>
                            <Typography style={{ color: 'black' }}>{transaction?.location.location_name}</Typography>
                        </Col>
                        <Col span={24}>
                            <Typography style={{ color: 'black' }}>{transaction?.location.addr}</Typography>
                        </Col>
                        <Col span={24}>
                            <Typography style={{ color: 'black' }}>{transaction?.location.city}, {transaction?.location.state} {transaction?.location.zipcode}</Typography>
                        </Col>
                    </Row>
                </Col>
                {/*<Col span={8} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>*/}
                {/*    <Button disabled>View On Map</Button>*/}
                {/*</Col>*/}
            </Row>
            <Row style={{ marginTop: '30px' }}>
                <Col span={24}>
                    <Row style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                        <Col span={24}>
                            <Typography style={{ fontWeight: '600', color: 'black' }}>Mechanism</Typography>
                        </Col>
                        <Col span={24}>
                            <Typography style={{ color: 'black' }}>{transaction?.mechanism.nickname || transaction?.mechanism.mechanism_type} (...{identifier})</Typography>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginTop: '30px' }}>
                <Col span={24}>
                    <Row style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                        <Col span={24}>
                            <Typography style={{ fontWeight: '600', color: 'black' }}>Payment Method</Typography>
                        </Col>
                        <Col span={24}>
                            <Typography style={{ color: 'black' }}>{transaction?.payment_method.nickname} (...{transaction?.payment_method.meta.card.last4})</Typography>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row style={{ marginTop: '30px' }}>
                <Col span={24}>
                    <Row style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                        <Col span={24}>
                            <Typography style={{ fontWeight: '600', color: 'black' }}>Details</Typography>
                        </Col>
                        <Col span={24}>
                            <Typography style={{ color: 'black' }}>{transaction?.payment.amount && formatCurrency(transaction?.payment.amount)}</Typography>
                        </Col>
                        <Col span={24}>
                            <Typography style={{ color: 'black' }}>{transaction?.payment.date_created && formatDateTime(transaction?.payment.date_created)}</Typography>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>

    )
}

export default TransactionDetailScreen
