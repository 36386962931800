import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Typography } from 'antd';
import { User } from '../models/User';
import { LoyaltyActivity } from '../models/Loyalty';
import { useParams } from 'react-router-dom'
import LoyaltyActivityListComponent from './LoyaltyActivityListComponent';
import { LoyaltyService } from '../services/LoyaltyService';

interface LoyaltyActivityScreenProps {
    user: User
    onError: (description: string, title?: string) => void
}
const LoyaltyActivityScreen: React.FC<LoyaltyActivityScreenProps> = (props) => {
    const [loading, setLoading] = useState<boolean>(false)
    const [loyaltyActivity, setLoyaltyActivity] = useState<LoyaltyActivity[]>([])
    const { id } = useParams()
    useEffect(() => {
        LoyaltyService.getLoyaltyActivityByLocation(id, 0, 100, props.user.access_token, setLoading).subscribe(
            (data: LoyaltyActivity[]) => {
                setLoyaltyActivity(data)

            }, (error: any) => {
                console.error(error)
                props.onError(error)
            }
        )
    }, [props.user.access_token])

    return <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column' }}>
        <Card style={{ height: '100%', width: '100%', textAlign: 'left' }} bodyStyle={{ paddingTop: 0 }} headStyle={{ minHeight: 0, marginTop: 12, marginBottom: 12 }} title={'Activty'} loading={loading}>
            <Row>
                <Col span={24}>
                    {loyaltyActivity.length == 0 &&
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Typography>Pay with YewPay to earn points today!</Typography>
                        </div>
                    }
                    <LoyaltyActivityListComponent loyaltyActivity={loyaltyActivity}/>
                </Col>
            </Row>
        </Card>
    </div>
};

export default LoyaltyActivityScreen;