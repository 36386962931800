import GeneralService from './GeneralService'
import {Component} from 'react'

export class WaitlistService extends Component {
  public static addWaitlistEntry(data: any, accessToken: any, loadingHandler: any) {
    return GeneralService.post(`/waitlist/join`, data, accessToken, loadingHandler, JSON)
  }
  public static getWaitlistEntry(waitlistType: string, accessToken: any, loadingHandler: any) {
    return GeneralService.get(`/waitlist/${waitlistType}`, accessToken, loadingHandler, JSON)
  }
}