import React, {type FC, useEffect, useState} from 'react'
import {Card, Col, Row, Typography} from 'antd'
import TransactionsListComponent from '../common/TransactionsList'
import {useLocation, useNavigate} from 'react-router-dom'
import {TransactionsService} from '../services/TransactionsService'
import {User} from '../models/User'
import {Transaction} from '../models/TransactionTypes'

interface AllTransactionsProps {
  user: User
  onError: (description: string, title?: string) => void
}

const AllTransactionsScreen: FC<AllTransactionsProps> = (props) => {
  const navigate = useNavigate()
  const location = useLocation();
  const state = location.state;

  const [transactions, setTransactions] = useState<Transaction[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    // TODO don't hardcode bounds
    if (state?.mechanism) {
      TransactionsService.getTransactionsByMechanism(state.mechanism.id, props.user.access_token, 0, 100, setLoading).subscribe((data: Transaction[]) => {
        setTransactions(data)
      }, (error: any) => {
        console.error(`Error fetching transactions: ${error}`);
        props.onError(error, `Error Fetching Transactions`);
      })
    } else if (state?.payment_method) {
      TransactionsService.getTransactionsByPaymentMethod(state.payment_method.id, props.user.access_token, 0, 100, setLoading).subscribe((data: Transaction[]) => {
        setTransactions(data)
      }, (error: any) => {
        console.error(`Error fetching transactions: ${error}`);
        props.onError(error, `Error Fetching Transactions`);
      })
    } else {
      TransactionsService.getTransactions(0, 100, props.user.access_token, setLoading).subscribe((data: Transaction[]) => {
        setTransactions(data)
      }, (error: any) => {
        console.error(`Error fetching transactions: ${error}`);
        props.onError(error, `Error Fetching Transactions`);
      })
    }
  }, [props.user])
  const goToTransaction = (transaction: Transaction) => {
    navigate(`/transactions/${transaction.payment.id}`)
  }

  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Row>
        <Col span={24} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          <Typography style={{ color: 'black', fontWeight: '600', fontSize: '18px' }}>Transactions</Typography>
        </Col>
      </Row>
      <Row style={{ flex: 1, overflow: 'auto', marginTop: '10px' }}>
        <Col span={24} style={{ width: '100%', height: '100%' }}>
          <Card style={{ height: '100%', overflowY: 'auto' }} loading={loading}>
            {transactions.length > 0 &&
              <TransactionsListComponent transactions={transactions} user={props.user} onClick={goToTransaction} />
            }
            {transactions.length == 0 &&
              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography>No transactions</Typography>
              </div>
            }
          </Card>
        </Col>
      </Row>
    </div>

  )
}

export default AllTransactionsScreen
