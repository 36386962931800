import React, {type FC, useState} from 'react'
import {PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {Button, Spin, Typography} from "antd";
import {User} from "../models/User";
import {PaymentMethodsService} from "../services/PaymentMethodsService";
import {PaymentMethod} from "../models/PaymentMethodTypes";
import {useNavigate} from "react-router-dom";

interface AddPaymentMethodProps {
    user: User
    onError: (description: string, title?: string) => void
    redirectRoute?: string
}

const AddPaymentMethodForm: FC<AddPaymentMethodProps> = (props) => {
    const navigate = useNavigate();
    const stripe: any = useStripe();
    const elements: any = useElements();
    const [loading, setLoading] = useState<boolean>(false);
    const [cardFormLoading, setCardFormLoading] = useState<boolean>(true);
    const [errorMessage, setErrorMessage] = useState();
    const [vaultDisabled, setVaultDisabled] = useState(true);

    const navToNewPaymentMethod = (paymentMethodId: any) => {
        setTimeout(() => {
            PaymentMethodsService.getCardByStripeId(paymentMethodId, props.user.access_token, setLoading).subscribe((data: any) => {
                if (data == null) {
                    goToAllPaymentMethods()
                } else if (props.redirectRoute) {
                    navigate(props.redirectRoute);
                } else {
                    const paymentMethod: PaymentMethod = data
                    goToPaymentMethod(paymentMethod.id)
                }
            }, (error: any) => {
                console.error(`Error fetching payment method: ${error}`);
                props.onError(error)
            });
        }, 2000);
    }



    const goToPaymentMethod = (paymentMethodId: any): void => {
        navigate(`/cards/${paymentMethodId}`)
    }

    const goToAllPaymentMethods = (): void => {
        navigate(`/cards`)
    }


    const handleError = (error: any) => {
        setLoading(false);
        setErrorMessage(error.message);
    }

    const handleSubmit = async (event: any) => {
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();

        if (!stripe) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        setLoading(true);

        // Trigger form validation and wallet collection
        const { error: submitError } = await elements.submit();
        if (submitError) {
            handleError(submitError);
            return;
        }

        PaymentMethodsService.getSetupIntent(props.user.access_token, null).subscribe((data: any) => {
            const { client_secret: clientSecret } = data;
            // Use the clientSecret and Elements instance to confirm the setup

            stripe.confirmSetup({
                elements,
                clientSecret,
                confirmParams: {
                    return_url: 'https://app.yewfi.com/cards',
                },
                // Uncomment below if you only want redirect for redirect-based payments
                redirect: "if_required",
            }).then(async (response: any) => {
                if (response.error) {
                    handleError(response.error)
                } else {
                    const setupIntent = response.setupIntent;
                    if (setupIntent.status == 'succeeded') {
                        const paymentMethodId = setupIntent.payment_method
                        navToNewPaymentMethod(paymentMethodId);
                    } else {
                        goToAllPaymentMethods()
                    }
                }
            }).catch((error: any) => {
                console.error(`Error calling stripe.confirmSetup: ${error}`)
                props.onError(error)
            });

        }, (error: any) => {
            console.error(`Error fetching setup intent: ${error}`);
            props.onError(error)
        });

    };
    return (
        <form onSubmit={handleSubmit} style={{ marginLeft: '10px', marginRight: '10px' }}>
            <PaymentElement onReady={() => { setVaultDisabled(false) }} onLoaderStart={() => { setCardFormLoading(false) }} />
            {cardFormLoading &&
                <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Spin size='large' />
                </div>
            }
            <Button type='primary' htmlType="submit" disabled={!stripe || loading || vaultDisabled} style={{ marginTop: '20px' }} loading={loading}>
                Vault Payment Method
            </Button>
            {errorMessage &&
                <div style={{ marginTop: '20px' }}>
                    <Typography style={{ color: 'red' }}>{errorMessage}</Typography>
                </div>
            }
        </form>
    )
}

export default AddPaymentMethodForm;
