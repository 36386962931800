import React, {FC, useCallback, useEffect, useState} from 'react';
import {Button, Input, Row, Typography} from 'antd';
import {User} from '../models/User';
import {UserService} from '../services/UserService';

interface PhoneVerificationProps {
    user: User | undefined
    setUser: (user: User) => void
    onError: (description: string, title?: string) => void
    fingerprint: string
    header: string
}
const PhoneVerification: FC<PhoneVerificationProps> = (props) => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [countdown, setCountdown] = useState(60);
    const [inputValue, setInputValue] = useState<number>();
    const handleInputChange = (e: any) => {
        const verificationCode = e.target.value;
        // Ensure the input value is numeric
        if (/^\d*$/.test(verificationCode)) {
            setInputValue(verificationCode);

            if (verificationCode.length === 6 && props.user) {
                UserService.verifyPhone(JSON.stringify({
                    'verification_code': `${verificationCode}`,
                    'fingerprint': props.fingerprint
                }), props.user.access_token, null).subscribe(
                    (data: User) => {
                        props.setUser(data)
                    }, (error: any) => {
                        console.error(`Error verifying phone ${error}`)
                        props.onError(error, 'Error Verifying Phone')

                    }
                )
            }
        }
    };

    useEffect(() => {
        if (countdown > 0 && isButtonDisabled) {
            const interval = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);

            return () => clearInterval(interval);
        } else if (countdown === 0) {
            setIsButtonDisabled(false);
        }
    }, [countdown, isButtonDisabled]);


    const resendVerification = useCallback((): void => {
        UserService.sendVerificationCode(props.user?.access_token, null).subscribe(
            (data: any) => {
                setIsButtonDisabled(true);
                setCountdown(60);
            }, (error: any) => {
                console.error(`Error requesting verification code ${error}`)
                props.onError(error, `Error Requesting Verification Code`)
                setIsButtonDisabled(true);
                setCountdown(60);
            }
        )
    }, [props.user]);
    useEffect(() => {
        if (props.user && (!props.user.phone_verified || !props.user.device_verified)) {
            resendVerification()
        }
    }, [props.user])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', padding: 20}}>
            <Row style={{marginTop: 10}}>
                <h2 style={{ fontWeight: '600' }}>{props.header}</h2>
                <Typography>Enter verification code sent to phone ending in {props.user?.phone.slice(-4)}</Typography>
            </Row>
            <Row style={{marginTop: 10}}>
                <Input size='large'
                    value={inputValue}
                    onKeyDown={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                            event.preventDefault();
                        }
                    }}
                    maxLength={6}
                       type={'text'}
                       inputMode={'numeric'}
                       autoComplete={'one-time-code'}
                       pattern={"\d{6}"}
                    onChange={handleInputChange} />
            </Row>
            <Row style={{marginTop: 10}}>
                <Button
                    type="primary"
                    disabled={isButtonDisabled}
                    onClick={resendVerification}
                >
                    {isButtonDisabled ? `Resend in ${countdown}s` : 'Resend Verification Code'}
                </Button>
            </Row>
        </div>
    );
};

export default PhoneVerification;
