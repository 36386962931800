import React, {type FC, useEffect, useState} from 'react'
import {Button, Card, Col, Row, Space, Typography} from 'antd'
import PaymentMethodsList from './PaymentMethodsList'
import {useNavigate} from 'react-router-dom'
import {PaymentMethodsService} from '../services/PaymentMethodsService'
import {User} from '../models/User'
import {PaymentMethod} from '../models/PaymentMethodTypes'
import { UserOutlined } from '@ant-design/icons'

interface AllPaymentMethodsScreenProps {
  user: User
  onError: (description: string, title?: string) => void
}

const AllPaymentMethodsScreen: FC<AllPaymentMethodsScreenProps> = (props) => {
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([])
  const navigate = useNavigate()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    // TODO don't hardcode bounds
    PaymentMethodsService.getCards(0, 100, props.user.access_token, setLoading).subscribe((data: PaymentMethod[]) => {
      setPaymentMethods(data)
    }, (error: any) => {
      console.error(`Error fetching transactions: ${error}`);
      props.onError(error, `Error Fetching Transactions`);
    })
  }, [props.user])
  const goToPaymentMethod = (paymentMethod: PaymentMethod): void => {
    navigate(`/cards/${paymentMethod.id}`)
  }
  const goToAddCard = (): void => {
    navigate(`/cards/new`)
  }
  const goToProfile = () => {
    navigate('/profile')
  }
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Row>
        <Col span={20} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          <Typography style={{ color: 'black', fontWeight: '600', fontSize: '18px' }}>Payment Methods</Typography>
        </Col>
        <Col span={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
          <Button icon={<UserOutlined />} shape='circle' onClick={goToProfile}></Button>
        </Col>
      </Row>
      <Space />
      <Row style={{ flex: 1, overflow: 'auto', marginTop: '10px' }}>
        <Col span={24} style={{ width: '100%', height: '100%' }}>
          <Card style={{ height: '100%', overflowY: 'auto', textAlign: 'left' }} title={`Credit Cards (${paymentMethods.length})`}
            extra={<a onClick={() => goToAddCard()}>Add Card</a>} loading={loading}>
            <PaymentMethodsList onClick={goToPaymentMethod} user={props.user} paymentMethods={paymentMethods} />
          </Card>
        </Col>
      </Row>
    </div>
  )
}
export default AllPaymentMethodsScreen
