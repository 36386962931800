import React from 'react';
import { Col, Divider, Row, Typography } from 'antd';
import { LoyaltyActivity } from '../models/Loyalty';
import { formatDateTime } from '../utils/DateUtils';

interface LoyaltyActivityListComponentProps {
    loyaltyActivity: LoyaltyActivity[]
}
const LoyaltyActivityListComponent: React.FC<LoyaltyActivityListComponentProps> = (props) => {
    const activity = props.loyaltyActivity.map((activity: LoyaltyActivity, index: number) =>
    (
        <div key={index}>
            <Row>
                <Col span={18}>
                    <Typography>
                        {formatDateTime(activity.date_created)}
                    </Typography>
                </Col>
                <Col span={4}>
                    {activity.activity_type == 'EARN' &&
                        <Typography style={{ color: '#7ac968' }}>+{activity.points_amount}</Typography>
                    }
                    {activity.activity_type == 'REDEEM' &&
                        <Typography style={{ color: 'red' }}>-{activity.points_amount}</Typography>
                    }
                </Col>
            </Row>
            {index < props.loyaltyActivity.length - 1 && <Divider />}
        </div>
    ))
    return <>
        {activity}
    </>
};

export default LoyaltyActivityListComponent;