import React, {type FC} from 'react'
import {Button, Typography} from 'antd'
import {User} from '../models/User'

interface AddVehicleInReviewProps {
    user: User,
    onComplete: any
}

const AddVehicleInReviewComponent: FC<AddVehicleInReviewProps> = (props) => {


    const done = () => {
        props.onComplete();
    }
    return (
        <div style={{ width: '100%' }}>
            <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography style={{ fontWeight: '600', margin: 0 }}>In Review</Typography>
                <Typography.Paragraph style={{ marginTop: '20px' }}>
                    Our team is reviewing this vehicle, and will complete our review within 24 hours. You'll receive a text message when your vehicle is approved and added, or if we require more information.
                </Typography.Paragraph>
            </div>
            <Button block type={'primary'} onClick={done}>Done</Button>
        </div>
    )
}

export default AddVehicleInReviewComponent
