import React from 'react'
import { Outlet } from 'react-router-dom'

const PaymentMethodsRoute = (): any => {
  return (
        <Outlet />
  )
}

export default PaymentMethodsRoute
