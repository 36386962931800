import React, {type FC, useCallback, useEffect, useRef, useState} from 'react'
import type Webcam from 'react-webcam'
import {Button, Input, Modal, Spin, Steps} from 'antd'
import EnrollFace from './EnrollFace'
import {UserService} from '../services/UserService'
import {User} from '../models/User'
import {PhoneNumberInput, registerPhone, enrollPhone} from './PhoneNumberInput'
import PhoneVerification from './PhoneVerification'
import CameraPermissions from "../utils/CameraPermissions";

interface RegisterAccountModalProps {
  visible: boolean
  setVisible: (visible: boolean) => void
  onRegister: (userData: any) => void
  onError: (description: string, title?: string) => void
  deeplink: string | undefined
  fingerprint: string
}

const RegisterAccountModal: FC<RegisterAccountModalProps> = (props) => {
  const [current, setCurrent] = useState(0)
  const [user, setUser] = useState<User>()
  const [phone, setPhone] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const next = () => {
    if (phone && phone.length == 12 && current == 0) {
      enrollPhone(phone, null, (data: any) => {
        setCurrent(current + 1)
        setUser(data)
      }, (error: any) => {
        console.error(error)
        props.onError(error)
      })
      setPhone('')
    } else {
      setCurrent(current + 1)
    }
  }

  const prev = () => {
    setCurrent(current - 1)
  }

  const webcamRef = useRef<Webcam | null>(null)

  const handleCancel = useCallback((): void => {
    // When the modal closes, stop the webcam stream
    const videoElem = webcamRef?.current?.video

    if ((videoElem != null) && (videoElem.srcObject != null)) {
      const stream = videoElem.srcObject as MediaStream
      const tracks = stream.getTracks()

      tracks.forEach((track) => {
        track.stop()
      })
    }

    props.setVisible(false)
  }, [])

  const handleRegister = useCallback((image: any): void => {
    setLoading(true)
    console.log(user)
    UserService.enrollFace(JSON.stringify({
      'image': image,
      'deeplink': props.deeplink,
      'fingerprint': props.fingerprint,
    }), user?.access_token, null).subscribe(
      (data: any) => {
        setUser(data?.user)
      }, (error: any) => {
        setLoading(false)
        console.error(`Error enrolling face ${error}`)
        try {
          const errorJson = JSON.parse(error)
          props.onError(errorJson.statusText || "Unknown error occurred. Please try again")
        } catch (err) {
          props.onError("Unknown error occurred. Please try again")
        }
      }
    )
  }, [props.fingerprint, user])

  useEffect(() => {
    if (user?.phone_verified && user?.phone && user?.device_verified && user?.face_associated) {
      props.onRegister({ user: user })
    }
    if (user?.phone_verified && user?.phone && user?.device_verified && !user?.face_associated) {
      setCurrent(2)
    }
    if (user && user?.phone && (!user?.phone_verified || !user?.device_verified)) {
      setCurrent(1)
    } else if (user && !user?.phone) {
      setCurrent(0)
    }
  }, [user])

  const steps = [
          {
      title: '',
      content: <>
        <PhoneNumberInput user={user} setPhone={setPhone} showHeader={true} />
        <Input size='large' style={{ display: 'none' }} />
      </>
    },
    {
      title: '',
      content: <PhoneVerification user={user} setUser={setUser} onError={props.onError} fingerprint={props.fingerprint} header='Verify Phone Number'/>
    },
    {
      title: '',
      content: <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <h2 style={{ fontWeight: '600' }}>Create Your Account</h2>
        <p>YewPay uses facial identification to secure your account. Your information is never accessible to anyone but <b>you</b>.</p>
      </div>
    },
    {
      title: '',
      content: <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <CameraPermissions/>
        <div><h2 style={{ fontWeight: '600' }}>Register Your Face ID</h2>
          <p>Register your Face ID with a single picture. You'll use your Face ID to log into your account.</p></div>
        {loading && <Spin size='large' />}
        {!loading && <div style={{ flexGrow: 1, width: '100%' }}>
          <EnrollFace handleImagesComplete={handleRegister} onError={props.onError} />
        </div >}
      </div >
    },
  ]

  const items = steps.map((item) => ({ key: item.title, title: item.title }))
  return (
    <Modal
      open={props.visible}
      onCancel={handleCancel}
      footer={null}
      style={{ display: 'flex', flexDirection: 'column', top: 0, marginTop: '10px' }}
      bodyStyle={{ height: '100%', display: 'flex', flexDirection: 'column', marginTop: '20px' }}>
      <Steps current={current} items={items} responsive={false} direction="horizontal" />
      {steps[current].content}
      <div>
        {current < steps.length - 1 && current != 1 && current != 3 && (
          <Button disabled={current == 0 && (!phone || phone.length < 12)} type="primary" onClick={() => { next() }}>
            Next
          </Button>
        )}
        {current > 0 && current != 2 && (
          <Button style={{ margin: '0 8px' }} onClick={() => { prev() }}>
            Previous
          </Button>
        )}
      </div>
    </Modal>
  )
}

export default RegisterAccountModal
