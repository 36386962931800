import React, {type FC, useCallback, useRef} from 'react'
import {Button} from 'antd'
import Webcam from "react-webcam";
import {User} from '../models/User';
import {MechanismsService} from '../services/MechanismsService';

interface CaptureVehicleProps {
    user: User,
    onError: (description: string, title?: string) => void
    onComplete: any
}

const CaptureVehicleComponent: FC<CaptureVehicleProps> = (props) => {
    const webcamRef = useRef<Webcam | null>(null)

    const handleCancel = useCallback((): void => {
        // When the modal closes, stop the webcam stream
        const videoElem = webcamRef?.current?.video

        if (videoElem?.srcObject != null) {
            const stream = videoElem.srcObject as MediaStream
            const tracks = stream.getTracks()

            tracks.forEach((track) => {
                track.stop()
            })
        }

    }, [])

    const handleCapture = useCallback((): void => {
        const imageSrc = webcamRef?.current?.getScreenshot();
        MechanismsService.enrollVehicle(JSON.stringify({
            'image': imageSrc,
        }), props.user.access_token, null).subscribe((data: any) => {
            handleCancel()
            props.onComplete()
        }, (error: any) => {
            props.onError(error, 'Error Enrolling Vehicle')
            console.error(error)
        })
    }, [webcamRef])

    const videoConstraints = {
        facingMode: 'environment',
        mirrored: false
    }


    return (
        <div style={{ width: '100%' }}>
            <div style={{ padding: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <h2 style={{ fontWeight: '600', margin: 0 }}>Snap Your Vehicle</h2>
                <p>Stand near the rear left side of your vehicle. Click the 'Capture' button when your vehicle is fully visible and centered in the camera view. Make sure the full rear license plate is in view. </p>
            </div>
            <div style={{ position: 'relative', flexGrow: 1, backgroundColor: '#000', width: '100%' }}>
                <Webcam
                    audio={false}
                    ref={webcamRef}
                    style={{ width: '100%', objectFit: 'cover' }}
                    videoConstraints={videoConstraints}
                    onUserMediaError={error => {
                        console.error('Webcam Error: ', error)
                        props.onError(error.toString(), 'Webcam Error')
                    }}
                />
            </div>
            <Button style={{ marginTop: '20px' }} block type={'primary'} onClick={handleCapture}>Capture</Button>
        </div>
    )
}

export default CaptureVehicleComponent
