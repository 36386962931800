import React, {type FC, useCallback, useEffect, useState} from 'react'
import {Button, Card, Col, Row, Space, Typography} from 'antd'
import {useNavigate} from 'react-router-dom'
import VehicleList from './VehicleList'
import {type Mechanism} from '../models/MechanismTypes'
import FaceSummary from './FaceSummary'
import {MechanismsService} from '../services/MechanismsService'
import {User} from '../models/User'
import {EnrollVehicle} from '../models/EnrollVehicle'
import {UserOutlined} from '@ant-design/icons'

interface AllMechanismsScreenProps {
  user: User
  onError: (description: string, title?: string) => void
}

const AllMechanismsScreen: FC<AllMechanismsScreenProps> = (props) => {
  const [vehicles, setVehicles] = useState<Mechanism[]>([])
  const [enrolledVehicles, setEnrolledVehicles] = useState<EnrollVehicle[]>([])
  const [face, setFace] = useState<Mechanism>()
  const [vehicleLoading, setVehicleLoading] = useState<boolean>(true)
  const [enrollVehicleLoading, setEnrollVehicleLoading] = useState<boolean>(true)
  const [faceLoading, setFaceLoading] = useState<boolean>(true)
  const getVehicles = useCallback(() => {
    MechanismsService.getVehicles(0, 3, props.user.access_token, setVehicleLoading).subscribe((data: Mechanism[]) => {
      setVehicles(data)
    }, (error: any) => {
      console.error(`Error fetching vehicles: ${error}`);
      props.onError(error)
    })
    MechanismsService.getEnrollmentPendingVehicles(0, 3, props.user.access_token, setEnrollVehicleLoading).subscribe((data: EnrollVehicle[]) => {
      setEnrolledVehicles(data)
    }, (error: any) => {
      console.error(`Error fetching enrollment pending vehicles: ${error}`);
      props.onError(error)
    })
  }, [props.user])
  useEffect(() => {
    getVehicles()
    MechanismsService.getFace(props.user.access_token, setFaceLoading).subscribe((data: Mechanism) => {
      setFace(data)
    }, (error: any) => {
      console.error(`Error fetching face: ${error}`);
      props.onError(error)
    })
  }, [props.user])
  const navigate = useNavigate()
  const goToVehicle = (vehicle: Mechanism): void => {
    navigate(`vehicles/${vehicle.meta.id}`)
  }

  const goToVehicles = (): void => {
    navigate(`vehicles`)
  }
  const goToProfile = (): void => {
    navigate(`/profile`)
  }

  const goToFace = (): void => {
    navigate('face')
  }

  const onAccept = useCallback((mechanism: Mechanism) => {
    mechanism.status = 'ACTIVE'
    MechanismsService.updateStatus(mechanism, props.user.access_token, setVehicleLoading).subscribe((data: any) => {
      getVehicles()
    }, (error: any) => {
      console.error(`Error activating vehicle: ${error}`);
      props.onError(error, `Error Activating Vehicle`);
    })
  }, [props.user])
  const onDecline = useCallback((mechanism: Mechanism) => {
    mechanism.status = 'DELETED';
    MechanismsService.deleteVehicle(mechanism, props.user.access_token, setVehicleLoading).subscribe((data: any) => {
      getVehicles()
    }, (error: any) => {
      console.error(`Error removing vehicle: ${error}`);
      props.onError(error, `Error Removing Vehicle`);
    })
  }, [props.user])
  useEffect(() => {
  }, [props.user])
  return (
    <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <Row>
        <Col span={20} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
          <Typography style={{ color: 'black', fontWeight: '600', fontSize: '18px' }}>Identifiers</Typography>
        </Col>
          <Col span={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
            <Button icon={<UserOutlined />} shape='circle' onClick={goToProfile}></Button>
          </Col>
      </Row>
      <Space />
      <Row style={{ marginTop: '10px', overflowY: 'auto' }}>
        <Col span={24} style={{ width: '100%', overflowY: 'auto', textAlign: 'left' }}>
          <Card title={'Vehicles'} style={{ overflowY: 'auto' }} bodyStyle={{}} loading={vehicleLoading || enrollVehicleLoading}
            extra={<a onClick={goToVehicles}>View All</a>}>
            <VehicleList onClick={goToVehicle} vehicleList={vehicles} enrolledVehicleList={enrolledVehicles} user={props.user} limit={2} onAccept={onAccept} onDecline={onDecline} />
          </Card>
        </Col>
      </Row>
      <Row style={{ overflow: 'auto', marginTop: '10px' }}>
        <Col span={24} style={{ width: '100%', height: '50%' }}>
          <Card style={{ overflowY: 'auto', textAlign: 'left' }} title={'Face'} loading={faceLoading}>
            <FaceSummary user={props.user} faceInfo={face} onClick={goToFace} />
          </Card>
        </Col>
      </Row>
    </div >
  )
}
export default AllMechanismsScreen
