import { Divider } from 'antd'
import PaymentMethodSummary from './PaymentMethodSummary'
import React, { type FC } from 'react'
import { User } from '../models/User'
import { PaymentMethod } from '../models/PaymentMethodTypes'

interface PaymentMethodsListProps {
  user: User
  paymentMethods: PaymentMethod[] | any
  onClick: any
}

const PaymentMethodsList: FC<PaymentMethodsListProps> = (props) => {
  return props.paymentMethods.map((paymentInfo: PaymentMethod, index: number) => (
    <div key={index}>
      <PaymentMethodSummary user={props.user} paymentInfo={paymentInfo} onClick={props.onClick} />
      {index < props.paymentMethods.length - 1 && <Divider />}
    </div>
  ))
}

export default PaymentMethodsList
