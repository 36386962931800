import React, {type FC} from 'react'
import {Button, Row, Typography} from 'antd'
import {useNavigate} from 'react-router-dom'
import {User} from '../models/User'
import card from '../assets/credit_card.svg';

interface NoCardScreenProps {
    user: User
    onError: (description: string, title?: string) => void
}


const NoCardScreen: FC<NoCardScreenProps> = (props) => {
    const navigate = useNavigate();
    const goToAddCard = (): void => {
        navigate(`/cards/new`)
    }
    const goToHome = () => {
        navigate('/')
    }

    return <div style={{textAlign: 'left', height: '100%'}}>
        <Row>
            <Typography style={{fontSize: 18, fontWeight: 600}}>Getting Started</Typography>
        </Row>
        <Row>
            <img src={card} alt="Credit Card" style={{width: '100%', zIndex: 2, paddingTop: '160px', paddingBottom: '40px'}}/>
        </Row>
        <Row style={{marginTop: 20}}>

            <Typography style={{fontSize: 18, fontWeight: 600}}>Almost There</Typography>
        </Row>
        <Row style={{marginTop: 20}}>
            <Typography>
                Securely vault a card to start earning rewards automatically at your favorite merchants when you pay.
            </Typography>
        </Row>
        <Row style={{marginTop: 40}}>
            <Button block type="primary" style={{fontWeight: 600, height: 40}} onClick={goToAddCard}>
                Add Your First Card
            </Button>
        </Row>
        <Row style={{marginTop: 20}}>
            <Button block type="link" style={{height: 40}} onClick={goToHome}>
                Not Right Now
            </Button>
        </Row>
    </div>
}
export default NoCardScreen;
