import React, {FC} from 'react'
import {TabBar} from 'antd-mobile'
import {useLocation, useNavigate,} from 'react-router-dom'
import {CarOutlined, CreditCardOutlined, HomeOutlined, StarOutlined, UserOutlined} from '@ant-design/icons'


const Menu: FC = () => {
    const location = useLocation()
    const { pathname } = location
    const topLevel = "/" + pathname.split('/')[1]
    const navigate = useNavigate()
    const tabs = [
        {
            key: '/',
            title: 'Home',
            icon: <HomeOutlined />,
        },
        {
            key: '/identifiers',
            title: 'Identifiers',
            icon: <CarOutlined />,
        },
        {
            key: '/rewards',
            title: 'Rewards',
            icon: <StarOutlined />,
        },
        {
            key: '/cards',
            title: 'Cards',
            icon: <CreditCardOutlined />,
        },
    ]
    if (topLevel == "/no_card") {
        return null;
    }

    return <div style={{
        flex: 0,
        backgroundColor: 'white',
        position: 'sticky',
        left: 0,
        bottom: 0,
        width: '100%'
    }}>

        <TabBar activeKey={topLevel} onChange={(value: any) => { navigate(value) }}>
            {tabs.map(item => (
                <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
            ))}
        </TabBar>
    </div>
}
export default Menu