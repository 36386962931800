import GeneralService from './GeneralService'
import { Component } from 'react'

export class TransactionsService extends Component {
  public static getTransactions(offset: number, limit: number, accessToken: any, loadingHandler: any) {
    return GeneralService.get(`/transactions/${offset}/${limit}`, accessToken, loadingHandler, JSON)
  }
  public static getTransactionByID(id: any, accessToken: any, loadingHandler: any) {
    return GeneralService.get(`/transactions/${id}`, accessToken, loadingHandler, JSON)
  }
  public static getTransactionsByPaymentMethod(paymentMethodId: number, accessToken: any, offset: number, limit: number, loadingHandler: any) {
    return GeneralService.get(`/transactions/payment_method/${paymentMethodId}/${offset}/${limit}`, accessToken, loadingHandler, JSON)
  }
  public static getTransactionsByMechanism(mechanismId: number, accessToken: any, offset: number, limit: number, loadingHandler: any) {
    return GeneralService.get(`/transactions/mechanism/${mechanismId}/${offset}/${limit}`, accessToken, loadingHandler, JSON)
  }
}