import React, { type FC, useEffect, useState } from 'react'
import { Button, Card, Col, Divider, Row, Typography } from 'antd'
import { User } from '../models/User'
import { UserService } from '../services/UserService'
import { formatCurrency } from '../utils/NumberUtils'
import { Credit } from '../models/Credit'
import { UserOutlined } from '@ant-design/icons'
import { useNavigate } from 'react-router-dom'
import { Promo } from '../models/Promo'
import { formatDate } from '../utils/DateUtils'
import { PromoService } from '../services/PromoService'
import LoyaltyPreview from './LoyaltyPreview'

interface RewardsScreenProps {
    user: User
    onError: (description: string, title?: string) => void
}

const RewardsScreen: FC<RewardsScreenProps> = (props) => {
    const [credits, setCredits] = useState<number>(0)
    const [creditAmount, setCreditAmount] = useState<number>(0)
    const [creditsLoading, setCreditsLoading] = useState<boolean>(true)
    const [promos, setPromos] = useState<Promo[]>([])
    const [promosLoading, setPromosLoading] = useState<boolean>(true)
    const [promosUpdated, setPromosUpdated] = useState<number>(0)
    const navigate = useNavigate()
    const goToProfile = () => {
        navigate('/profile')
    }
    useEffect(() => {
        const initialLoad = promosLoading
        UserService.getCredits(props.user.access_token, initialLoad ? setCreditsLoading : null).subscribe(
            (data: Credit[]) => {
                let totalCredits = 0
                let creditCount = 0
                data?.forEach(credit => {
                    if (credit.amount_consumed < credit.amount) {
                        totalCredits += (credit.amount - credit.amount_consumed)
                        creditCount += 1
                    }
                });
                setCreditAmount(totalCredits)
                setCredits(creditCount)
            },
            (error: any) => {
                console.error(`Error getting credits ${error}`)
                props.onError(error)
            }
        )
        PromoService.getPromos(props.user.access_token, initialLoad ? setPromosLoading : null).subscribe(
            (data: Promo[]) => {
                setPromos(data)

            },
            (error: any) => {
                console.error(`Error getting credits ${error}`)
                props.onError(error)
            }
        )
    }, [props.user, promosUpdated])
    const claimPromo = (promo_id: number) => {
        PromoService.claimPromo(promo_id, props.user.access_token, null).subscribe(
            (data: any) => {
                setPromosUpdated(promosUpdated + 1)
            },
            (error: any) => {
                console.error(`Error claiming promo ${error}`)
                props.onError(error)
            }
        )
    }
    let creditVerbiage = <Typography>You have no credits. Invite friends to YewPay to earn some!</Typography>
    if (credits > 0) {
        creditVerbiage = <Typography>You have {credits} credit{credits > 1 && 's'} worth <span style={{ color: '#7ac968', fontWeight: 'bold' }}>{formatCurrency(creditAmount)}</span>.</Typography>
    }
    let promoRender: any = promos.map((promo: Promo, index: number) => {
        let deal = ""
        switch (promo.promo_type) {
            case "PCT_OFF":
                deal = `${promo.promo_value * 100}% off `
                break;

            default:
                return
        }
        let claimButton;
        if (promo.promo_claimed) {
            claimButton = <Button disabled>Claimed</Button>
        } else {
            claimButton = <Button onClick={() => {
                claimPromo(promo.id)
            }}>Claim</Button>
        }
        return <Row key={index}>
            <Col span={18}>
                <Typography>{promo.location.location_name} - <span style={{ fontWeight: 'bold' }}>{deal}</span></Typography>
                <Typography>{formatDate(promo.date_start, false)}-{formatDate(promo.date_end, false)}</Typography>
            </Col>
            <Col span={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                {claimButton}
            </Col>
            {index < promos.length - 1 && <Divider />}
        </Row>
    })

    return (
        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
            <Row>
                <Col span={20} style={{ display: 'flex', flexDirection: 'column', alignItems: 'start' }}>
                    <Typography style={{ color: 'black', fontWeight: '600', fontSize: '18px' }}>Rewards</Typography>
                </Col>
                <Col span={4} style={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                    <Button icon={<UserOutlined />} shape='circle' onClick={goToProfile}></Button>
                </Col>
            </Row>
            <Row style={{ marginTop: '20px' }}>
                <Col span={24} style={{ width: '100%', overflowY: 'auto' }}>
                    <Card style={{ overflowY: 'auto', textAlign: 'left' }} title={'Credits'} loading={creditsLoading}>
                        {creditVerbiage}
                    </Card>
                </Col>
            </Row>
            <Row style={{ marginTop: '20px' }}>
                <Col span={24} style={{ width: '100%', overflowY: 'auto' }}>
                    <Card style={{ overflowY: 'auto', textAlign: 'left' }} title={'Promos'} loading={promosLoading}>
                        {promoRender.length > 0 ? promoRender : "No promos available at this time"}
                    </Card>
                </Col>
            </Row>
            <Row style={{ marginTop: '20px' }}>
                <Col span={24} style={{ width: '100%', overflowY: 'auto' }}>
                    <LoyaltyPreview user={props.user} onError={props.onError} />
                </Col>
            </Row>
        </div >
    )
}
export default RewardsScreen 
